<template>
    <div class="nk-msg-body bg-white profile-shown">
        <div class="nk-msg-head">
            <h4 class="title d-none d-lg-block">{{ message.subject }}zzz</h4>
            <div class="nk-msg-head-meta">
                <div class="d-none d-lg-block">
                    <ul class="nk-msg-tags">
                        <li><span class="label-tag"><em class="icon ni ni-flag-fill"></em> <span>Issue Type</span></span></li>
                    </ul>
                </div>
                <div class="d-lg-none"><a href="#" class="btn btn-icon btn-trigger nk-msg-hide ml-n1"><em class="icon ni ni-arrow-left"></em></a></div>
                <ul class="nk-msg-actions">
                    <li><a href="#" class="btn btn-dim btn-sm btn-outline-light"><em class="icon ni ni-check"></em><span>Status Button</span></a></li>
                    <!-- <li><span class="badge badge-dim badge-success badge-sm"><em class="icon ni ni-check"></em><span>Closed</span></span></li> -->
                    <li class="d-lg-none"><a href="#" class="btn btn-icon btn-sm btn-white btn-light profile-toggle"><em class="icon ni ni-info-i"></em></a></li>
                    <li class="dropdown">
                        <a-dropdown :placement="placement">
                            <div class="dropdown">
                            <a class="text-soft dropdown-toggle btn btn-icon btn-trigger">
                                <em class="icon ni ni-more-h"></em></a>
                            </div>
                            <a-menu slot="overlay">
                            <a-menu-item>
                                <a><i class="bg-success-dim ni ni-edit mr-1"></i> Mark as complete</a>
                            </a-menu-item>
                            <a-menu-item>
                                <a><i class="ni ni-delete mr-1 bg-danger-dim"></i> Push to other user</a>
                            </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </li>
                </ul>
            </div>
        </div><!-- .nk-msg-head -->
        <div class="nk-msg-reply nk-reply" data-simplebar>
            <div class="nk-reply-item">
                <div class="nk-reply-header">
                    <div class="user-card">
                        <div class="user-avatar sm bg-blue">
                            <span>{{ message.clientFirstName.substring(0,1) + message.clientLastName.substring(0,1) }}</span>
                        </div>
                        <div class="user-name">{{ message.clientFirstName + ' ' + message.clientLastName }}</div>
                    </div>
                    <div class="date-time">{{ $moment(message.createdDate).format('h:mm a | DD/MM/YYYY') }}</div>
                </div>
                <div class="nk-reply-body">
                    <div class="nk-reply-entry entry">
                        <p>Issue raised</p>
                    </div>
                    <div class="attach-files">
                        <ul class="attach-list">
                            <li class="attach-item">
                                <a class="download" href="#"><em class="icon ni ni-img"></em><span>Download of attachment</span></a>
                            </li>
                            <li class="attach-item">
                                <a class="download" href="#"><em class="icon ni ni-img"></em><span>Download of attachment</span></a>
                            </li>
                        </ul>
                        <div class="attach-foot">
                            <span class="attach-info">Count Attachments</span>
                            <a class="attach-download link" href="#"><em class="icon ni ni-download"></em><span>Download All</span></a>
                        </div>
                    </div>
                </div>
            </div><!-- .nk-reply-item -->
            <div class="nk-reply-item">
                <div class="nk-reply-header">
                    <div class="user-card">
                        <div class="user-avatar sm bg-pink">
                            <span>ST</span>
                        </div>
                        <div class="user-name">Support Team <span>(You)</span></div>
                    </div>
                    <div class="date-time">{{ $moment(message.createdDate).format('h:mm a | DD/MM/YYYY') }}</div>
                </div>
                <div class="nk-reply-body">
                    <div class="nk-reply-entry entry">
                        <p>My reply</p>
                    </div>
                    <div class="nk-reply-from"> Replied by <span>userFullName</span> at Time </div>
                </div>
            </div><!-- .nk-reply-item -->
            <div class="nk-reply-meta">
                <div class="nk-reply-meta-info"><span class="who">User</span> assigned user: <span class="whom">James Prendergast</span> at {{ $moment(message.createdDate).format('h:mm a | DD/MM/YYYY') }}</div>
            </div><!-- .nk-reply-meta -->
                
            <div class="nk-reply-form">
                <div class="nk-reply-form-header">
                    <ul class="nav nav-tabs-s2 nav-tabs nav-tabs-sm">
                        <li class="nav-item">
                            <a class="nav-link active" data-toggle="tab" href="#reply-form">Reply</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" data-toggle="tab" href="#note-form">Private Note</a>
                        </li>
                    </ul>
                    <div class="nk-reply-form-title">
                        <div class="title">Reply as:</div>
                        <div class="user-avatar xs bg-purple">
                            <span>IH</span>
                        </div>
                    </div>
                </div>
                <div class="tab-content">
                    <div class="tab-pane active" id="reply-form">
                        <replyForm />
                    </div>
                </div>
            </div><!-- .nk-reply-form -->
        </div><!-- .nk-reply -->
    </div>
</template>

<script>
import replyForm from '@/components/messages/replyForm.vue'

export default {
    props: {
        messageId: { type: Number }
    },
    components: { replyForm },
    data() {
        return {
            visible: true,
            placement: 'topCenter',
            message: { messageId: 1, subject: 'Need Sage updated', clientFirstName: 'Richard', clientLastName: 'Tracey', message: 'Hi, I need Sage updated as soon as possible!', createdDate: new Date(), issueType: 'IT Support', unread: false }
        }
    },
    created() {
        //Need to run get message list function here
    },
    methods: {
        //Need get message list function here
    }

}
</script>

<style>

</style>