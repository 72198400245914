<template>
  <div class="nk-msg">
      <div class="nk-chat-aside">
          <div class="search-content">
            <a-input-search v-model="searchFilter" allow-clear placeholder="Search for message" style="width: 350px; margin: auto; display: block;">
            </a-input-search>
          </div>
        <a-tabs default-active-key="1">
          <a-tab-pane key="1" tab="Active">
            <div class="nk-msg-list" data-simplebar>
                <div class="nk-msg-item" v-bind:key="message.messageId" v-for="message in filteredMessages">
                    <div class="nk-msg-media user-avatar">
                        <span>{{ message.clientFirstName.substring(0,1) + message.clientLastName.substring(0,1) }}</span>
                    </div>
                    <div class="nk-msg-info">
                        <div class="nk-msg-from">
                            <div class="nk-msg-sender">
                                <h6 class="subtitle">{{ message.subject.substring(0,11)+"..." }}</h6>
                            </div>
                            <div class="nk-msg-meta">
                                <div class="date">{{ $moment(message.createdDate).format('h:mm a | DD/MM/YYYY') }}</div>
                                <div v-if="message.unread" class="lable-tag dot bg-blue" style="margin: 4px;"></div>
                            </div>
                        </div>
                        <div class="nk-msg-context">
                            <div class="nk-msg-text">
                                
                                <p>{{ message.message.substring(0,35)+"..." }}</p>
                            </div>
                            <div class="nk-msg-lables">
                                <div class="asterisk"><a href="#"><em class="asterisk-off icon ni ni-star"></em><em class="asterisk-on icon ni ni-star-fill"></em></a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="Closed">
            Closed
        </a-tab-pane>
        <a-tab-pane key="3" tab="Starred">
            Starred
        </a-tab-pane>
        <a-tab-pane key="4" tab="All">
            All
        </a-tab-pane>
        </a-tabs>
    </div><!-- .nk-msg-nav -->

    <messageChat/>
    
    <div class="nk-msg-profile visible" data-simplebar>
        <profile/>
    </div><!-- .nk-msg-profile -->
  </div><!-- .nk-msg -->
</template>

<script>
import profile from '@/components/messages/profile.vue'
import messageChat from '@/components/messages/messageChat.vue'

export default {
  components: { profile, messageChat },
  data() {
    return {
        messages: [
            { messageId: 1, subject: 'Need Sage updated', clientFirstName: 'Richard', clientLastName: 'Tracey', message: 'Hi, I need Sage updated as soon as possible!', createdDate: new Date(), issueType: 'IT Support', unread: false },
            { messageId: 2, subject: 'Need Sage updated', clientFirstName: 'Richard', clientLastName: 'Tracey', message: 'Hi, I need Sage updated as soon as possible!', createdDate: new Date(), issueType: 'IT Support', unread: true }
        ],
        searchFilter: '',
        visible: true,
        placement: 'topCenter',
        clientFullName: this.clientFirstName + ' ' + this.clientLastName
    }
  },
  computed: {
    filteredMessages: function() {
        if (this.searchFilter != '') {
            return this.messages.filter((m) => {  return m.client.match(this.searchFilter) })
        }
        return this.messages 
    }
  },
  created() {
      this.getView()
  },
  methods: {
      getView() {
        this.$http.get('/ViewAccess/messages')
        .then(() => {
        })
        .catch(() => {
        })
    },
  }
}
</script>

<style>

</style>