<template>
  <div class="card">
        <div class="card-inner-group">
            <div class="card-inner">
                <div class="user-card user-card-s2 mb-2">
                    <div class="user-avatar md bg-primary">
                        <span>RT</span>
                    </div>
                    <div class="user-info">
                        <h5>{{ client.firstName + ' ' + client.lastName }}</h5>
                        <span class="sub-text">Customer</span>
                    </div>
                    <div class="user-card-menu dropdown">
                        <a href="#" class="btn btn-icon btn-sm btn-trigger dropdown-toggle" data-toggle="dropdown"><em class="icon ni ni-more-h"></em></a>
                        <div class="dropdown-menu dropdown-menu-right">
                            <ul class="link-list-opt no-bdr">
                                <li><a href="#"><em class="icon ni ni-eye"></em><span>View Profile</span></a></li>
                                <li><a href="#"><em class="icon ni ni-na"></em><span>Ban From System</span></a></li>
                                <li><a href="#"><em class="icon ni ni-repeat"></em><span>View Orders</span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row text-center g-1">
                    <div class="col-4">
                        <div class="profile-stats">
                            <span class="amount">23</span>
                            <span class="sub-text">Total Issues</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="profile-stats">
                            <span class="amount">20</span>
                            <span class="sub-text">Complete</span>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="profile-stats">
                            <span class="amount">3</span>
                            <span class="sub-text">In Progress</span>
                        </div>
                    </div>
                </div>
            </div><!-- .card-inner -->
            <div class="card-inner">
                <div class="aside-wg">
                    <h6 class="overline-title-alt mb-2">User Information</h6>
                    <ul class="user-contacts">
                        <li>
                            <em class="icon ni ni-mail"></em><span>{{ client.email }}</span>
                        </li>
                        <li>
                            <em class="icon ni ni-call"></em><span>{{ client.phone }}</span>
                        </li>
                        <li>
                            <em class="icon ni ni-map-pin"></em><span>1134 Ridder Park Road <br>San Fransisco, CA 94851</span>
                        </li>
                    </ul>
                </div>
                <div class="aside-wg">
                    <h6 class="overline-title-alt mb-2">Additional</h6>
                    <div class="row gx-1 gy-3">
                        <div class="col-6">
                            <span class="sub-text">Ref ID: </span>
                            <span>TID-049583</span>
                        </div>
                        <div class="col-6">
                            <span class="sub-text">Requested:</span>
                            <span>{{ client.firstName + ' ' + client.lastName }}</span>
                        </div>
                        <div class="col-6">
                            <span class="sub-text">Status:</span>
                            <span class="lead-text text-success">Open</span>
                        </div>
                        <div class="col-6">
                            <span class="sub-text">Last Reply:</span>
                            <span>{{ client.firstName + ' ' + client.lastName }}</span>
                        </div>
                    </div>
                </div>
                <div class="aside-wg">
                    <h6 class="overline-title-alt mb-2">Assigned Account</h6>
                    <ul class="align-center g-2">
                        <li>
                            <div class="user-avatar bg-purple">
                                <span>IH</span>
                            </div>
                        </li>
                        <li>
                            <div class="user-avatar bg-pink">
                                <span>ST</span>
                            </div>
                        </li>
                        <li>
                            <div class="user-avatar bg-gray">
                                <span>SI</span>
                            </div>
                        </li>
                    </ul>
                </div>
            </div><!-- .card-inner -->
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            client: { firstName: 'Richard', lastName: 'Tracey', email: 'richard@ukitnetworks.com', phone: '01702 413170', mobile: '0744556677'}
        }
    },

}
</script>

<style>

</style>