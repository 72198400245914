<template>
  <div class="nk-reply-form-editor">
        <div class="nk-reply-form-field">
            <textarea class="form-control form-control-simple no-resize" placeholder="Hello"></textarea>
        </div>
        <div class="nk-reply-form-tools">
            <ul class="nk-reply-form-actions g-1">
                <li class="mr-2"><button class="btn btn-primary" type="submit">Reply</button></li>
                <li>
                    <div class="dropdown">
                        <a class="btn btn-icon btn-sm btn-tooltip" data-toggle="dropdown" title="Templates" href="#"><em class="icon ni ni-hash"></em></a>
                        <div class="dropdown-menu">
                            <ul class="link-list-opt no-bdr link-list-template">
                                <li class="opt-head"><span>Quick Insert</span></li>
                                <li><a href="#"><span>Thank you message</span></a></li>
                                <li><a href="#"><span>Your issues solved</span></a></li>
                                <li><a href="#"><span>Thank you message</span></a></li>
                                <li class="divider">
                                <li><a href="#"><em class="icon ni ni-file-plus"></em><span>Save as Template</span></a></li>
                                <li><a href="#"><em class="icon ni ni-notes-alt"></em><span>Manage Template</span></a></li>
                            </ul>
                        </div>
                    </div>
                </li>
                <li>
                    <a class="btn btn-icon btn-sm" data-toggle="tooltip" data-placement="top" title="Upload Attachment" href="#"><em class="icon ni ni-clip-v"></em></a>
                </li>
                <li>
                    <a class="btn btn-icon btn-sm" data-toggle="tooltip" data-placement="top" title="Insert Emoji" href="#"><em class="icon ni ni-happy"></em></a>
                </li>
                <li>
                    <a class="btn btn-icon btn-sm" data-toggle="tooltip" data-placement="top" title="Upload Images" href="#"><em class="icon ni ni-img"></em></a>
                </li>
            </ul>
            <div class="dropdown">
                <a href="#" class="dropdown-toggle btn-trigger btn btn-icon mr-n2" data-toggle="dropdown"><em class="icon ni ni-more-v"></em></a>
                <div class="dropdown-menu dropdown-menu-right">
                    <ul class="link-list-opt no-bdr">
                        <li><a href="#"><span>Another Option</span></a></li>
                        <li><a href="#"><span>More Option</span></a></li>
                    </ul>
                </div>
            </div>
        </div><!-- .nk-reply-form-tools -->
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>